function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h2',[_vm._v("Publisher")]),_vm._v(" "),_c('hr'),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h5',[_vm._v("With the support of")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('p',[_vm._v("Under contract:")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('hr'),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('hr'),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/news"}},[_c('font-awesome-icon',{attrs:{"icon":"newspaper"}}),_vm._v("\n    Announcements\n")],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('address',[_c('a',{attrs:{"href":"https://neurosonology-bg.com/","target":"_blank"}},[_c('strong',[_vm._v("The Bulgarian Society of Neurosonology and Cerebral Hemodynamics")])]),_vm._v(" "),_c('br'),_c('em',[_vm._v("Chairman of BSNCH: Assoc. Prof. I. Velcheva, MD, PhD")]),_vm._v(" "),_c('br'),_vm._v("Tvardishki prohod 23 str., floor 4, office 12\n    "),_c('br'),_vm._v("1404 Sofia, Bulgaria\n    "),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('address',[_vm._v("\n    Tel: "),_c('a',{attrs:{"href":"tel:+35929225454"}},[_vm._v("+359 2 9225454")]),_c('br'),_vm._v("\n    Email: "),_c('a',{attrs:{"href":"mailto:velchevairena@gmail.com"}},[_vm._v("velchevairena@gmail.com")]),_c('br'),_vm._v("\n    Web: "),_c('a',{attrs:{"href":"https://neurosonology-bg.com/","target":"_blank"}},[_vm._v("neurosonology-bg.com")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":"/images/fni_logo_en.png","alt":"FNI"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_c('strong',[_vm._v("КП-06-НП1/53")]),_vm._v(" 18.12.2019")]),_vm._v(" "),_c('li',[_c('strong',[_vm._v("КП-06-НП2/29")]),_vm._v(" 04.12.2020")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":"/images/ads/ad.cavinton.forte.jpg","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{attrs:{"href":"https://www.woerwagpharma.bg/bg/"}},[_c('img',{attrs:{"src":"/images/ads/ad.wph.jpg","alt":""}})])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/contacts","lang":"en"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }