function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"})}
var staticRenderFns = []

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journal/index","lang":"en","links":[{"name":"Editors","type":"editors"},{"name":"Ethics","type":"ethics"},{"name":"Submission","type":"instructions"}],"articleFilters":[{"label":"Title","value":"title"},{"label":"Full Text","value":"content"},{"label":"Keywords","value":"keywords"},{"label":"Abstract","value":"abstract"}],"authorFilters":[{"label":"Full Name","value":"fullName"},{"label":"Institution","value":"institutions"},{"label":"ORCID","value":"orcid"}],"availableCards":[{"name":"Issues","route":"issues"},{"name":"Articles","route":"articles"},{"name":"Authors","route":"authors"}],"items":[{"name":"Neurosonology","issn":"1312-6431"}]},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }