function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Акад. Титянова с нова световна номинация")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h6',[_vm._v("16 април 2021")]),_vm._v(" "),_c('p',[_c('img',{attrs:{"src":"/images/womeonstroke-768x432.jpg","alt":"Жените в борбата с мозъчен инсулт"}}),_vm._v("\nАкадемик Екатерина Титянова е номинирана за член на инициативата „Жените в борбата с мозъчен инсулт“ към World Stroke Organization. Тя е единствен представител на България в тази световна инициатива.")]),_vm._v(" "),_c('p',[_vm._v("Допълнителна информация за инициативата и номинираните може да прочетете "),_c('a',{attrs:{"href":"https://www.world-stroke.org/news-and-blog/blogs/womeninstroke-initiative-our-nominees"}},[_vm._v("тук")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/news/article-1","lang":"bg","order":3,"title":"Акад. Титянова с нова световна номинация","subtitle":"16 април 2021","image":"/images/womeonstroke-768x432.jpg","description":"Академик Екатерина Титянова е номинирана за член на инициативата „Жените в борбата с мозъчен инсулт“ към World Stroke Organization. Тя е единствен представител на България в тази световна инициатива."},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }