function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Journal of Neurosonology and Cerebral Hemodynamics")]),_vm._v(" "),_c('p',[_vm._v("The Journal is multidisciplinary and enables the reader to be informed about the latest\ndevelopments in ultrasound diagnostics of the nervous system and cerebral\nhemodynamics in various fields of medicine – neurology, neonatology, pediatrics,\nangiology, vascular surgery, neurosurgery, imaging diagnostics and others. The Journal\nis a platform for sharing scholarly and practical experience of experts by publishing\nauthor articles, reviews, letters, and scientific information in their headings.  There are\nno publication fees payable by authors.\n ")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1312-6431","lang":"en","title":"Contents","description":"The latest developments in ultrasound diagnostics of the nervous system and cerebral hemodynamics in various fields of medicine – neurology, neonatology, pediatrics, vascular surgery, imaging diagnostics and others.","issn":"1312-6431","image":"/images/cover-1312-6431.jpg","order":1,"banner":"/images/header-bg.gif","customHeader":"<a href=\"https://neurosonology-bg.com/en/\" target=\"_blank\">\n  <img src=\"/images/banner_9congress-en.jpg\" class=\"8 congress\" />\n</a>\n"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }