function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Acad. Titianova with a new international nomination")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h6',[_vm._v("16 Apr 2021")]),_vm._v(" "),_c('p',[_c('img',{attrs:{"src":"/images/womeonstroke-768x432.jpg","alt":"Women in stroke"}}),_vm._v("\nAcademician Ekaterina Тitianova has been nominated to be a member of the “Women in stroke” initiative, launched by the World Stroke Organization. She is the only person to represent Bulgaria at this international project.")]),_vm._v(" "),_c('p',[_vm._v("Additional information about “Women in stroke” and the nominees is available at: "),_c('a',{attrs:{"href":"https://www.world-stroke.org/news-and-blog/blogs/womeninstroke-initiative-our-nominees"}},[_vm._v("https://www.world-stroke.org/news-and-blog/blogs/womeninstroke-initiative-our-nominees")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/news/article-1","lang":"en","order":3,"title":"Acad. Titianova with a new international nomination","subtitle":"16 Apr 2021","image":"/images/womeonstroke-768x432.jpg","description":"Academician Ekaterina Тitianova has been nominated to be a member of the “Women in stroke” initiative, launched by the World Stroke Organization. She is the only person to represent Bulgaria at this international project."},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }