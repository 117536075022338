function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Д-р Ивайло Петров с голямата награда “Невросонолог нагодината – 2020”")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h6',[_vm._v("януари 2020")]),_vm._v(" "),_c('p',[_c('img',{attrs:{"src":"/images/ipetrov-1-768x510.jpg","alt":"Dr. Ivaylo Petrov"}}),_vm._v("\nЗа трети път бе връчена наградата „Невросонолог на годината – 2020“. Получи я д-р Ивайло\nПетров, началник на отделение по нервни болести в МБАЛ Шумен, учредител и член на\nУправителния съвет на Българската асоциация по невросонология и мозъчна хемодинамика.")]),_vm._v(" "),_c('p',[_vm._v("Престижната награда му бе връчена от акад. проф. Екатерина Титянова по време на 6-ия\nнационален конгрес на Българската асоциация по невросонология и мозъчна хемодинамика.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/news/article-2","lang":"bg","order":2,"title":"Д-р Ивайло Петров с голямата награда “Невросонолог на годината – 2020”","subtitle":"януари 2020","image":"/images/ipetrov-1-768x510.jpg","description":"Престижната награда му бе връчена от акад. проф. Екатерина Титянова по време на 6-ия национален конгрес на Българската асоциация по невросонология и мозъчна хемодинамика."},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }