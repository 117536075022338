function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Невросонология и Мозъчна Хемодинамика")]),_vm._v(" "),_c('p',[_vm._v("Списанието е мултидисциплинарно и дава възможност на читателя да бъде\nинформиран за най-новите разработки в ултразвуковата диагностика на нервната\nсистема и мозъчната хемодинамика в различни области на медицината –\nневрология, неонатология, педиатрия, ангиология, съдова хирургия,\nневрохирургия, образна диагностика и други. Публикуваните материали са изцяло\nс отворен достъп. Списанието е платформа за споделяне на научен и практически\nопит на експерти чрез публикуване на авторски статии, рецензии, писма и научна\nинформация в техните рубрики. Няма такси за публикуване, дължими от авторите.")]),_vm._v(" "),_c('p')])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1312-6431","lang":"bg","title":"Съдържание","description":"Най-новите разработки в ултразвуковата диагностика на нервната система и мозъчната хемодинамика в различни области на медицината – неврология, неонатология, педиатрия, съдова хирургия, образна диагностика и други.","issn":"1312-6431","order":1,"image":"/images/cover-1312-6431.jpg","banner":"/images/header-bg.gif","customHeader":"<a href=\"https://neurosonology-bg.com/\" target=\"_blank\">\n  <img src=\"/images/banner_9congress-bg.jpg\" class=\"8 congress\" />\n</a>\n"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }