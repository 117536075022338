function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Dr. Ivaylo Petrov with the Grand Prize “Neurosonologist of the Year – 2020″!")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h6',[_vm._v("Jan 2020")]),_vm._v(" "),_c('p',[_c('img',{attrs:{"src":"/images/ipetrov-1-768x510.jpg","alt":"Dr. Ivaylo Petrov"}}),_vm._v("\nThe prestigious award was presented by Academician Prof. Ekaterina Titianova during the 6th National Congress of the Bulgarian Society of Neurosonology and Cerebral Hemodynamics.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/news/article-2","lang":"en","order":2,"title":"Dr. Ivaylo Petrov with the Grand Prize “Neurosonologist of the Year – 2020″","subtitle":"Jan 2020","image":"/images/ipetrov-1-768x510.jpg","description":"The prestigious award was presented by Academician Prof. Ekaterina Titianova during the 6th National Congress of the Bulgarian Society of Neurosonology and Cerebral Hemodynamics."},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }